@font-face {
  font-family: "Open Sans";
  src: url(./assets/fonts/Open_Sans/OpenSans-VariableFont_wght.ttf)
    format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url(./assets/fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf)
    format("truetype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "Open Sans";
  src: url(./assets/fonts/Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf)
    format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf)
    format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf)
    format("truetype");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Montserrat Black";
  src: url(./assets/fonts/Montserrat-Black.ttf) format("truetype");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "Gibson";
  src: url(./assets/fonts/Gibson-Regular.otf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gibson";
  src: url(./assets/fonts/Gibson-SemiBold.otf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gibson Light";
  src: url(./assets/fonts/Gibson-Light.ttf) format("truetype");
}

@font-face {
  font-family: "SF UI Display Medium";
  font-style: normal;
  font-weight: normal;
  src:
    local("SF UI Display Medium"),
    url(./assets/fonts/sf-ui-display-medium-58646be638f96.woff) format("woff");
}

@font-face {
  font-family: "Maven Pro";
  src: url(./assets/fonts/Maven_Pro/MavenPro-VariableFont_wght.ttf)
    format("truetype");
}
@font-face {
  font-family: "Maven Pro";
  font-weight: bold;
  src: url(./assets/fonts/Maven_Pro/static/MavenPro-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Maven Pro";
  font-weight: medium;
  src: url(./assets/fonts/Maven_Pro/static/MavenPro-Medium.ttf)
    format("truetype");
}
@font-face {
  font-family: "Maven Pro";
  font-weight: 400;
  src: url(./assets/fonts/Maven_Pro/static/MavenPro-Regular.ttf)
    format("truetype");
}

html {
  margin-top: 0 !important;
}

body {
  margin: 0;
  font-family: "Open Sans", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

#beacon-container {
  position: absolute;
  z-index: 120000;
}

.smartbanner-show .smartbanner {
  display: block;
  position: fixed;
  bottom: 0;
  top: auto;
  z-index: 130000;
}

.smartbanner-android {
  box-shadow: inset 0 4px 0 #e6007e !important;
}
